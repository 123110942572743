@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

*{
  font-family: 'Poppins', sans-serif;
}

.form_container{
  min-width: 30%;
}

@media screen and(max-width:1000px){
  .form_container{
    width: 45%;
  }
}

@media screen and(max-width:700px){
  .form_container{
    width: 65%;
  }
}

.inicio-titulo{
  font-size: 2rem;
  text-align: center;
  padding: 250px;
}

.page-container{
  color: white;
}

.tarefas-page{
  color: #333;
  padding: 35px;
  background-color: #0D6EFD;
  
}
.app{
  max-width: 600px;
  margin: 0 auto 300px;
  background-color: #efefef;
  padding: 20px 30px;
  border-radius: 15px;
}

.app h1{
  text-align: center;
}

.app p{
  margin: 10px 0;
}

.app input{
  box-sizing: border-box;
}

.app button{
  background-color: #0B5ED7;
  color: #FFF;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  opacity: .8;
  transition: .3s;
  margin-right: 10px;
}

.app button:hover{
  opacity: 1;
}

.todo-list{
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #555;
}

.todo{
  background-color: #FFF;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app .complete{
  background-color: #5cb85c;
}

.app .delete{
  background-color: #D9534f;
}

.todo-form{
  padding-bottom: 1.5rem;
}

.app input, select{
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #DDD;
  border-radius: 3px;
  width: 100%;
}

.filter, .search{
  border-bottom: 1px solid #555;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.filter-options{
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.filter-options div{
  flex: 1 1 0;
}

.priority, .date{
  font-size: .9rem;
}

.description{
  overflow-wrap: break-word;
  max-width: 300px;
}

.criar-tarefa-btn{
  margin-top: 10px;
}

.priority-edit{
  margin-bottom: 10px;
}

.home-image{
  width: 30%;
  border-radius: 50%;
}

.flex-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
}

.container-inicio{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: black;
  width: 600px;
  padding: 15px;
  gap: 10px;
  border-radius: 15px;
  
}

.container-inicio p{
  width: 90%;
  text-align: justify;
}

.container-sobre p{
  width: 90%;
  text-align: justify;
}
.container-sobre h4{
  width: 90%;
  font-size: 1.2rem;
}

.btn-success{
  opacity: 0.9;
}


.fa-solid{
  margin-right: 5px;
}

.about-img{
  width: 171px;
  border-radius: 50%;
}

.sobre-page{
  padding: 35px;
  background-color: #0D6EFD;
}

.container-sobre{
  max-width: 600px;
  margin: 0 auto 300px;
  background-color: white;
  padding: 20px 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}